const SmoothScroll = require('smooth-scroll')
require('picturefill')
const lazySizes = require('lazysizes')
require('lazysizes/plugins/bgset/ls.bgset')
require('lazysizes/plugins/parent-fit/ls.parent-fit')
new SmoothScroll('a[href*="#"]')

require('AOS').init({
	once: true,
	offset: 0
})

const fursuitopenbtns = document.getElementsByClassName('fursuit-open-btn')
const fursuitclosebtns = document.getElementsByClassName('fursuit-close-btn')
const variantstogglebtns = document.getElementsByClassName('variants-toggle-btn')

if (fursuitopenbtns) {
	for (let i = 0; i < fursuitopenbtns.length; i++) {
		fursuitopenbtns[i].addEventListener('click', function () {
			document.getElementById('fursuit-' + this.getAttribute('data-id')).classList.add('fursona-fursuit--active')
		})
	}
}

if (fursuitclosebtns) {
	for (let i = 0; i < fursuitclosebtns.length; i++) {
		fursuitclosebtns[i].addEventListener('click', function () {
			document.getElementById('fursuit-' + this.getAttribute('data-id')).classList.remove('fursona-fursuit--active')
		})
	}
}

if (variantstogglebtns) {
	for (let i = 0; i < variantstogglebtns.length; i++) {
		variantstogglebtns[i].addEventListener('click', function () {
			if (!document.getElementById('variants-' + this.getAttribute('data-id')).classList.contains('fursona-variants--active')) {
				document.getElementById('variants-' + this.getAttribute('data-id')).classList.add('fursona-variants--active')
				this.innerText = 'Hide fursona variants'
			} else {
				document.getElementById('variants-' + this.getAttribute('data-id')).classList.remove('fursona-variants--active')
				this.innerText = 'View fursona variants'
			}
		})
	}
}
